import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Link,
  useParams,
} from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { logging } from 'w3-user-ui-component';
import uilibPackageJson from 'w3-user-ui-component/package.json';

import packageJson from '../package.json';

// FOR TESTING -> can be removed when connected to backend.
// import DummyBasicData from './assets/data/DummyProfileData';
import ErrorIcon from './assets/icons/userlib/Error';
import ShrugIllustration from './assets/illustrations/ShrugIllustartion';
import Activity from './components/Activity';
import Banner from './components/Banner';
import Certificates from './components/Certificates';
import ContactFooter from './components/ContactFooter';
import ContactModal from './components/ContactModal';
import Discord from './components/Discord';
import Education from './components/Education';
import Experience from './components/Experience';
import Interests from './components/Interests';
import Notifications from './components/Notifications';
import {
  NotificationDismissType,
  NotificationType,
} from './components/Notifications/utils/TypesInterfaces';
import Skills from './components/Skills';
import Spaces from './components/Spaces';
import User from './components/User';
import LoadingBox from './components/userlib/LoadingBox';
import { NotificationStateContext } from './context/NotificationContext';
import { ProfileStateContext } from './context/ProfileDataContext';
import useFooterPosition from './hooks/UseFooterPosition';
import {
  ComponentTypes,
  IActivity,
  ICertificates,
  IDefault as IComponentDefault,
  IInterests,
  ISocialMedia,
  IUserProfileConfiguration,
  SocialMediaTypes,
} from './utils/interfaces/DbInterfaces';
import { getPathfinderApiUrl } from './utils/pathsUtil';

import './App.scss';

/**
 * Heading in notification when something has gone wrong
 */
const ERROR_HEADING = 'Something went wrong';

// TESTING only, can be removed in final
// const TestingFailLoadin = false;

const PublicProfile = () => {
  const { userId } = useParams();
  const { profileData, setProfileData } = useContext(ProfileStateContext);
  const { notifications, dismissNotification, addNNotification } = useContext(NotificationStateContext);

  const [isLoading, setIsLoading] = useState(false);
  const [userIsPublished, setUserIsPublished] = useState(false);
  const footerRef = React.createRef<HTMLDivElement>();
  const [initiatedPage, setInitiatedPage] = useState(false);
  const [initiateFotter, setInitiateFotter] = useState(false);
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const footerTimeoutId = useRef<NodeJS.Timeout>();
  const useIsFooterSetToFixed = useFooterPosition(footerRef, [initiateFotter]);
  const [responseText, setResponseText] = useState('');
  const mapSocialMedia = useCallback((data: any, type: SocialMediaTypes, field: keyof typeof data.contacts): ISocialMedia | null => {
    const contact = data?.contacts?.[field];

    if (contact) {
      return {
        type,
        userName: contact.value,
        visible: contact.visible,
      };
    }

    return null;
  }, []);

  // hook for initiating first time positioning of bottom banner
  useEffect(() => {
    if (initiatedPage && footerRef.current && !footerTimeoutId.current) {
      footerTimeoutId.current = setTimeout(() => setInitiateFotter(true), 400);
    }
  }, [footerRef, initiatedPage]);

  // hook for when component is loaded
  useEffect(() => {
    if (!initiatedPage && !isLoading) {
      logging.logAll('Public Profile version: ', packageJson.version);
      logging.logAll('Shared lib version: ', uilibPackageJson.version);

      setIsLoading(true);

      const baseUrl = getPathfinderApiUrl();
      fetch(`${baseUrl}/public-profile-api/${userId}`)
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 404) {
              throw new Error('404');
            } else {
              throw new Error('Something went wrong.');
            }
          }

          return res.json();
        })
        .then((data) => {
          setResponseText('');
          setUserIsPublished(true);

          // Do mapping with existing structure
          const mappedData = {
            id: userId,
            userId: data.userId,
            published: true,
            createdAt: data.createdAt,
            basic: {},
            socialMedias: [] as ISocialMedia[],
            sections: [] as any[],
          };

          if (data.personal) {
            Object.assign(mappedData.basic, {
              firstName: data.personal.firstName,
              lastName: data.personal.lastName,
              profilePicture: data.personal.profilePicture,
              dateOfBirth: data.personal?.dateOfBirth,
              currentJobTitle: data.personal?.currentJobTitle,
            });
          }

          if (data.aboutMe) {
            Object.assign(mappedData.basic, {
              aboutMe: data.aboutMe,
            });
          }

          if (data.activity) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Activity,
              visible: true,
              stars: data.activity.stars,
              lessonsRead: data.activity.lessonsRead,
              exercisePoints: data.activity.exercisePoints,
              quizPoints: data.activity.quizPoints,
              totalPoints: data.activity.totalPoints,
            } as IActivity);
          }

          if (data.interests) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Interests,
              visible: true,
              tags: data.interests,
            } as IInterests);
          }

          if (data.certifications) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Certificates,
              visible: true,
              certificates: data.certifications,
            } as ICertificates);
          }

          if (data.skills) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Skills,
              visible: true,
              skills: data.skills,
            });
          }

          if (data.spaces) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Spaces,
              visible: true,
              spaces: data.spaces,
            });
          }

          if (data.education) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Education,
              visible: true,
              education: data.education,
            });
          }

          if (data.experience) {
            // @ts-ignore
            mappedData.sections.push({
              type: ComponentTypes.Experience,
              visible: true,
              experience: data.experience,
            });
          }

          // Mapping Social Medias
          const socialMediaTypes: { [key: string]: keyof typeof data.contacts } = {
            Email: 'email',
            Phone: 'phone',
            LinkedIn: 'linkedin',
            Twitter: 'twitter',
            GitHub: 'github',
          };
          Object.entries(socialMediaTypes).forEach(([type, field]) => {
            const media = mapSocialMedia(data, type as SocialMediaTypes, field);
            if (media) mappedData.socialMedias.push(media);
          });

          setProfileData(mappedData as unknown as IUserProfileConfiguration);
        })
        .catch((err) => {
          logging.logDebug('error: ', err);

          if (err.message === '404') {
            setResponseText('User does not have a public profile.');
          } else {
            const myNotif: NotificationType = {
              id: uuidv4(),
              dismissed: false,
              type: 'error',
              header: ERROR_HEADING,
              body: <div>Something went wrong loading the profile</div>,
            };

            setResponseText('An error occured fetching the profile data.');
            addNNotification(myNotif);
          }
        })
        .finally(() => {
          setInitiatedPage(true);
          setIsLoading(false);
        });
    }
  }, [addNNotification, initiatedPage, isLoading, mapSocialMedia, setProfileData, userId]);

  // hook for setting bottom banner to bottom:0px when fixed position
  useEffect(() => {
    if (footerRef.current) {
      if (useIsFooterSetToFixed) {
        footerRef.current.style.bottom = '0px';
      } else {
        footerRef.current.style.bottom = '';
      }
    }
  }, [footerRef, useIsFooterSetToFixed]);

  const closeModal = () => {
    setShowContactInfoModal(false);
  };

  const clickOpenContactMeModal = () => {
    setShowContactInfoModal(true);
  };

  const clickedAchievementItem = (type: ComponentTypes) => {
    logging.logDebug('PublicProfile -> app -> clickedAchievementItem -> type', type);
    let elementId: string | undefined;

    switch (type) {
      case ComponentTypes.Certificates:
        elementId = 'certificates';
        break;
      case ComponentTypes.Activity:
        elementId = 'activity';
        break;
      case ComponentTypes.Discord:
        elementId = 'discord';
        break;
      default:
        elementId = undefined;
    }

    if (elementId) {
      document.getElementById(elementId)?.scrollIntoView();
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBox text='Loading...' />
      ) : (
        <div className='PublicProfile-body'>
          {userIsPublished ? (
            <>
              <User openContactMeModal={clickOpenContactMeModal} clickedAchievementItem={clickedAchievementItem} />

              {profileData.sections.map((item: IComponentDefault) => {
                if (item.visible) {
                  switch (item.type) {
                    case ComponentTypes.Certificates:
                      return <Certificates key='component-certificates' id='certificates' />;
                    case ComponentTypes.Discord:
                      return <Discord key='component-discord' id='discord' />;
                    case ComponentTypes.Activity:
                      return <Activity key='component-activity' id='activity' />;
                    case ComponentTypes.Interests:
                      return <Interests key='component-interests' id='interests' />;
                    case ComponentTypes.Spaces:
                      return <Spaces key='component-spaces' id='spaces' />;
                    case ComponentTypes.Education:
                      return <Education key='component-education' id='education' />;
                    case ComponentTypes.Experience:
                      return <Experience key='component-experience' id='experience' />;
                    case ComponentTypes.Skills:
                      return <Skills key='component-skills' id='skills' />;
                    default:
                      return (
                        <div>
                          <ErrorIcon /> <strong>Data Error!</strong> Trying to insert an unknown component type: <strong>{item.type}</strong>
                        </div>
                      );
                  }
                } else {
                  return null;
                }
              })}

              <div className='PublicProfile-contact-footer-wrapper'>
                <div>{`${profileData.basic.firstName} ${profileData.basic.lastName}`} &copy; 2025</div>
                {!useIsFooterSetToFixed && <ContactFooter clickOpenContactModal={() => setShowContactInfoModal(true)} />}
                <Link to={`/report-abuse/${userId}`} className='PublicProfile-nabuse-btn' target='_blank' rel='noopener noreferrer'>
                  Report Abuse
                </Link>
              </div>
            </>
          ) : (
            initiatedPage && (
              <div className='PublicProfile-no-user'>
                <ShrugIllustration />
                <div>{responseText}</div>
              </div>
            )
          )}
        </div>
      )}

      {initiatedPage && <Banner mRef={footerRef} />}

      {initiatedPage && <ContactModal show={showContactInfoModal} handleClose={closeModal} />}

      <Notifications
        className='PublicProfile-notifications'
        notifications={notifications}
        onClose={(notif: NotificationDismissType) => dismissNotification(notif.id)}
      />
    </>
  );
};

export default PublicProfile;
