import React from 'react';

import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import styles from './Banner.module.scss';

interface IBanner extends ICompDefault {
  mRef?: React.Ref<HTMLDivElement> | null,
}

const Banner = ({ className, mRef }: IBanner) => (
  <div ref={mRef || null} className={`${styles.banner_wrapper}${className ? ` ${className}` : ''}`}>
    <a href='https://www.w3schools.com' target='_blank' rel='noopener noreferrer' className={styles.banner_link} >
      <div>Create your own profile at w3schools.com today.</div>
    </a>
  </div>
);

Banner.defaultProps = {
  mRef: undefined,
};
export default Banner;
